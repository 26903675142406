import { lazy, Suspense } from "react";
import Index from "./jsx/index";
import { Route, Switch, withRouter } from "react-router-dom";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AuthProvider, { useAuth } from "./context/AuthContext";

const theme = createTheme();

function ThemedApp(props) {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider props={props}>
        <App {...props} />
      </AuthProvider>
    </ThemeProvider>
  );
}

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {isAuthenticated ? (
          <Index />
        ) : (
          <div className="vh-100">
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/page-register" component={SignUp} />
              <Route path="/page-forgot-password" component={ForgotPassword} />
            </Switch>
          </div>
        )}
      </Suspense>
    </>
  );
}

export default withRouter(ThemedApp);
