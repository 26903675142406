import React, { useState } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { compose } from "redux";
import { Table } from "react-bootstrap";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { renderField } from "./fields";
import { atLeastOne, number, required } from "./validation";
import BudgetItems from "../../table/BudgetItems.js";

const renderSubCategories = ({ fields, meta: { error, submitFailed } }) => (
  <>
    <tr className="bg-light">
      <td colSpan={3}>
        <button
          type="button"
          onClick={() => fields.push({})}
          className="btn btn-link"
        >
          Add Subcategory
        </button>
        {submitFailed && error && <p className="fs-12 text-danger">{error}</p>}
      </td>
    </tr>
    {fields && fields.length > 0 && (
      <tr className="bg-light">
        <td colSpan={3}>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <strong>#</strong>
                </th>
                <th>
                  <strong>Name</strong>
                </th>
                <th>
                  <strong>Cost</strong>
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {fields.map((item, index) => (
                <Row
                  key={index}
                  item={item}
                  index={index}
                  handleRemove={fields.remove}
                />
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    )}
  </>
);

const renderItems = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <div>
      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-primary rounded light mr-3"
      >
        + ADD
      </button>
      {submitFailed && error && <p className="fs-12 text-danger">{error}</p>}
    </div>
    <BudgetItems
      title="Category"
      columns={[{ name: "Name" }]}
      fields={fields}
      nested={(item) => ({
        name: `${item}.subcategories`,
        component: renderSubCategories,
        validate: atLeastOne,
      })}
      rowFields={(item) => [
        {
          name: `${item}.name`,
          type: "text",
          component: renderField,
          validate: required,
        },
      ]}
    />
  </div>
);

const variants = {
  hover: {
    opacity: 1,
    scale: 1.1,
  },
  initial: {
    opacity: 0,
    scale: 1,
  },
};

const Row = ({ index, item, handleRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <motion.tr onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <td>{index + 1}</td>
      <td>
        <Field
          name={`${item}.item_name`}
          type="text"
          component={renderField}
          placeholder="Enter only the cost item name; not the cost itself"
          validate={required}
        />
      </td>
      <td>
        <Field
          name={`${item}.item_cost`}
          type="number"
          component={renderField}
          placeholder="Enter the cost here"
          validate={[required, number]}
          parse={(value) => +value}
        />
      </td>
      <td>
        <motion.i
          variants={variants}
          animate={isHovered ? "hover" : "initial"}
          className="fa fa-close text-danger fs-20 border-red-600 rounded-circle"
          role="button"
          onClick={() => handleRemove(index)}
        />
      </td>
    </motion.tr>
  );
};

const ItemizedBudget = (props) => {
  const { handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        <div className="col-sm-12">
          <p className="font-w600">Add Badget categories below</p>
          <FieldArray
            name="budget.itemized_budget"
            validate={atLeastOne}
            component={renderItems}
          />
        </div>
        <div className="col-md-12">
          <p className="fs-14">
            i.e. hard costs, soft costs, capex, financing costs, etc... Do not
            include purchase price as this was entered earlier.
          </p>
        </div>
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(ItemizedBudget);
