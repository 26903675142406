// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfkSH8TjMAdYdeFDLlP6sCJcEI6BT5XmU",
  authDomain: "app.xprop.io",
  databaseURL: "https://bcpa-sc-default-rtdb.firebaseio.com",
  projectId: "bcpa-sc",
  storageBucket: "bcpa-sc.appspot.com",
  messagingSenderId: "333192684313",
  appId: "1:333192684313:web:3906ae9eb5acf0bfb89b88",
  measurementId: "G-Q7BNT4BMEN"
};

// Initialize Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
}
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { analytics, app, auth, db };
