import { required, number } from "./validation";

const states = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const renderField = ({
  input,
  label,
  placeholder,
  helper,
  type,
  min = 0,
  meta: { touched, error },
}) => (
  <div className="input-field-redux form-group">
    {label && <label className="font-w600">{label}</label>}
    {helper && <p className="fs-14">{helper}</p>}
    <div>
      <input
        {...input}
        placeholder={placeholder ?? label}
        className="form-control"
        type={type}
        min={type === "number" ? min : undefined}
      />
      {touched && error && <span className="fs-12 text-danger">{error}</span>}
    </div>
  </div>
);

const renderStateSelector = ({
  input,
  label,
  helper,
  meta: { touched, error },
}) => (
  <div className="input-field-redux form-group">
    <label className="font-w600">{label}</label>
    {helper && <p className="fs-14">{helper}</p>}
    <select className="form-control" {...input}>
      <option value="">Select a State...</option>
      {states.map((val) => (
        <option value={val} key={val}>
          {val}
        </option>
      ))}
    </select>
    {touched && error && <span>{error}</span>}
  </div>
);

const renderRadio = ({
  input,
  type,
  label,
  noMargin,
  meta: { touched, error },
}) => {
  return (
    <div className={`input-field-redux form-group${noMargin ? " mb-0" : ""}`}>
      <div className="form-check">
        <label className="form-check-label">
          <input {...input} type={type} className="form-check-input" />
          {label}
        </label>
      </div>
    </div>
  );
};

export const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <p className="fs-12 text-danger mb-4">{error}</p> : false;

export const propertyInfoFields = [
  {
    name: "address.street",
    type: "text",
    label: "Street Address",
    component: renderField,
  },
  { name: "address.city", type: "text", label: "City", component: renderField },
  {
    name: "address.state",
    type: "text",
    label: "State",
    component: renderStateSelector,
  },
  {
    name: "address.zip",
    type: "text",
    label: "Zip Code",
    component: renderField,
  },
  {
    name: "address.county",
    type: "text",
    label: "County",
    component: renderField,
  },
];

export const analysisTermFields = [
  {
    name: "analysis_term",
    type: "number",
    label:
      "What is your purchase price?",
    // helper: "Enter only digits - i.e. for 5 years simply enter '5'",
    placeholder: "Amount in years",
    component: renderField,
    validate: [required, number],
  },
];

export const purchasePriceFields = [
  {
    name: "price",
    type: "number",
    label: "Please enter your purchase price below",
    helper:
      "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign",
    placeholder: "Purchase Price",
    component: renderField,
    validate: [required, number],
  },
];

export const budgetTypeFields = [
  {
    name: "budget.type",
    type: "radio",
    label: "How would you like to enter the remainder of your budget?",
    component: renderRadio,
    options: [
      { label: "As a total number for all costs", value: "total_cost" },
      { label: "As a total number for each cost", value: "simple_budget" },
      { label: "As an itemized budget", value: "itemized_budget" },
    ],
    validate: required,
  },
  // {
  //   referenceField: "budget.type",
  //   referenceAnswer: "total_cost",
  //   name: "budget.total_cost",
  //   type: "number",
  //   label: "Please enter the total amount for closing costs below",
  //   helper:
  //     "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign",
  //   component: renderField,
  // },
  // {
  //   referenceField: "budget.type",
  //   referenceAnswer: "simple_budget",
  //   name: "budget.simple_budget",
  //   type: "number",
  //   label: "Please enter the total amount for closing costs below",
  //   helper:
  //     "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign",
  //   component: renderField,
  // },
];

export const budgetTypeTotalCostFields = [
  {
    name: "budget.total_cost",
    type: "number",
    label: "Please enter the total amount for closing costs below",
    helper:
      "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign",
    component: renderField,
    validate: [required, number],
  },
];

export const seniorDebtFields = [
  {
    name: "financing.type",
    type: "radio",
    label: "Will this acquisition be financed with senior debt?",
    component: renderRadio,
    options: [
      { label: "Yes", value: "has_senior_debt" },
      { label: "No", value: "no_senior_debt" },
    ],
    validate: required,
  },
];

export const seniorDebtSizingTypeFields = [
  {
    name: "financing.senior_debt.sizing_type",
    type: "radio",
    label: "How will you be entering the size of your senior loan?",
    component: renderRadio,
    options: [
      {
        label:
          "Dollar Amount - Choose this option if you would like to size the loan as a dollar amount",
        value: "dollar_amt",
      },
      {
        label:
          "LTV - choose this option if you would like to size the loan as a ratio of the purchase price and will not be financing any part of the budget besides the purchase price",
        value: "loan_to_value",
      },
      {
        label:
          "LTC - choose this option if you would like to size the loan as a ratio of the budget and will be financing some or all of the items on the budget",
        value: "loan_to_cost",
      },
    ],
    validate: required,
  },
];

export const seniorDebtSizingTypeDAFields = [
  {
    name: "financing.senior_debt.dollar_amt",
    type: "number",
    label: "Please enter the dollar amount for the loan below",
    helper:
      "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign",
    placeholder: "Dollar Amount",
    component: renderField,
    validate: [required, number],
  },
];

export const seniorDebtSizingTypeLTVFields = [
  {
    name: "financing.senior_debt.loan_to_value",
    type: "number",
    label: "Please enter the LTV ratio for the loan below",
    helper:
      "Just enter digits - nothing else; i.e. for 75% simply type '75' - do not add '%'",
    placeholder: "Loan to Value",
    component: renderField,
    validate: [required, number],
  },
];

export const seniorDebtAssumptionsFields = [
  {
    name: "financing.senior_debt.assumptions.interest_rate",
    type: "number",
    label: "Please enter the interest rate below for the senior loan",
    helper:
      "Just enter digits - nothing else; i.e. for 5% enter '5' - do not add enter '5%' or '0.5'",
    placeholder: "Interest rate",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.senior_debt.assumptions.interest_only_months",
    type: "number",
    label:
      "Please enter below the length of the interest-only component (in months) for your senior loan",
    placeholder: "Please enter months; not years",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.senior_debt.assumptions.term",
    type: "number",
    label:
      "Please enter below the amount of months over which the senior loan will be paid off.",
    placeholder: "Please enter months; not years",
    component: renderField,
    validate: [required, number],
  },
];

export const multiTermDisbursementFields = [
  {
    name: "financing.senior_debt.disbursement.type",
    type: "radio",
    label: "Will all the senior debt be disbursed at time zero?",
    component: renderRadio,
    options: [
      {
        label: "Yes; all senior debt will be disbursed at time zero",
        value: "at_once",
      },
      {
        label:
          "No; some or all of the senior debt will be disbursed after time zero",
        value: "multiple",
      },
    ],
    validate: required,
  },
];

export const incomeEntryFields = [
  {
    name: "income.type",
    type: "radio",
    label: "How would you like to enter your rental income?",
    component: renderRadio,
    options: [
      {
        label: "Gross Annual Income",
        value: "gross_annual_income",
      },
      {
        label: "Unit Mix",
        value: "unit_mix",
      },
    ],
    validate: required,
  },
];

export const incomeEntryGrossFields = [
  {
    name: "income.gross_annual_income",
    type: "number",
    label: "Please enter the gross annual income for the property below.",
    helper:
      "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign. The income entered is the in-place income and will be inflated by the inflation rate for Y1. If you would like the amount above to be the Y1 income, discount it by the inflation rate you intend to use.",
    placeholder: "Gross annual income",
    component: renderField,
    validate: [required, number],
  },
];

// export const incomeEntryUnitUpsideFields = [
//   {
//     name: "income.unit_mix.upside.monthly_rent",
//     type: "number",
//     label: "Please enter the gross annual income for the property below.",
//     helper:
//       "Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign. The income entered is the in-place income and will be inflated by the inflation rate for Y1. If you would like the amount above to be the Y1 income, discount it by the inflation rate you intend to use.",
//     placeholder: "Gross annual income",
//     component: renderField,
//   },
// ];

export const incomeInflationFields = [
  {
    name: "income.inflation_rate",
    type: "number",
    label: "Please enter income inflation rate below.",
    helper:
      "Just enter digits - nothing else - i.e. for 3% simply type '3'; not '3%' or '0.3'",
    placeholder: "Inflation rate",
    component: renderField,
    validate: [required, number],
  },
];

export const otherIncomeFields = [
  {
    name: "income.other_income",
    type: "radio",
    label:
      "Will there be other income besides the income from the apartments (such as cell phone tower etc. . . )?",
    component: renderRadio,
    options: [
      {
        label: "Yes",
        value: "has_other_income",
      },
      {
        label: "No",
        value: "no_other_income",
      },
    ],
    validate: required,
  },
];

export const expensesFields = [
  {
    name: "expenses.type",
    type: "radio",
    label: "Will there be expenses for this property?",
    component: renderRadio,
    options: [
      {
        label: "Yes - there will be expenses for this property",
        value: "has_expenses",
      },
      {
        label: "No - this is a NNN lease property",
        value: "no_expenses",
      },
    ],
    validate: required,
  },
];

export const refinanceFields = [
  {
    name: "financing.refinance.type",
    type: "radio",
    label: "Will there be a refinance for this property?",
    component: renderRadio,
    options: [
      {
        label: "Yes",
        value: "has_refinance",
      },
      {
        label: "No",
        value: "no_refinance",
      },
    ],
    validate: required,
  },
];

export const refinanceItemsFields = [
  {
    name: "financing.refinance.year",
    type: "number",
    label: "Year of refinance",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.refinance.valuation_cap_rate",
    type: "number",
    label: "Valuation cap rate",
    placeholder:
      "Just enter digits - nothing else - i.e. for 3% simply type '3'; not '3%' or '0.3'",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.refinance.loan_to_value",
    type: "number",
    label: "LTV",
    placeholder:
      "Just enter digits - nothing else - i.e. for 75% simply type '75'; not '75%' or '0.75'",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.refinance.closing_costs",
    type: "number",
    label:
      "Please enter closing costs below as a percentage of the loan proceeds",
    placeholder:
      "Just enter digits - nothing else - i.e. for 3% simply type '3'; not '3%' or '0.3'",
    component: renderField,
    validate: [required, number],
  },
];

export const refinanceDebtAssumptionsFields = [
  {
    name: "financing.refinance.new_loan.interest_rate",
    type: "number",
    label: "Please enter the interest rate below for the refinance loan",
    placeholder:
      "Just enter digits - nothing else - i.e. for 3% simply type '3'; not '3%' or '0.3'",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.refinance.new_loan.intereset_only_months",
    type: "number",
    label:
      "Please enter below the length of the interest-only component (in months) for your refinance loan",
    placeholder: "Please enter months; not years",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "financing.refinance.new_loan.term",
    type: "number",
    label:
      "Please enter below the amount of months over which the refinance loan will be paid off",
    placeholder: "Please enter months; not years",
    component: renderField,
    validate: [required, number],
  },
];

export const dispositionFields = [
  {
    name: "disposition.exit_cap_rate",
    type: "number",
    label: "Exit cap rate",
    placeholder:
      "Just enter digits - nothing else - i.e. for 3% simply type '3'; not '3%' or '0.3'",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "disposition.closing_cost_rate",
    type: "number",
    label:
      "Please enter closing costs below as a percentage of the sale proceeds",
    placeholder:
      "Just enter digits - nothing else - i.e. for 3% simply type '3'; not '3%' or '0.3'",
    component: renderField,
    validate: [required, number],
  },
];

export const waterfallFields = [
  {
    name: "cash_flow.type",
    type: "radio",
    label: "Do you need to divide the cash flow in any way between investors?",
    component: renderRadio,
    options: [
      {
        label: "Yes",
        value: "has_waterfall",
      },
      {
        label: "No",
        value: "no_waterfall",
      },
    ],
    validate: required,
  },
];

export const waterfallItemsFields = [
  {
    name: "cash_flow.equity",
    type: "number",
    label: "LP equity amount",
    placeholder:
      "Please enter just number; i.e. for $100,000 enter '100000'. Do not enter dollar sign or comma",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "cash_flow.preferred_return",
    type: "number",
    label: "Preferred return",
    placeholder:
      "Please enter just number; i.e. for 5% enter '5'. Do not enter '5%' or '0.05'",
    component: renderField,
    validate: [required, number],
  },
  {
    name: "cash_flow.post_pref_split",
    type: "number",
    label: "Post-ref split",
    placeholder:
      "Please enter just number; i.e. for 5% enter '5'. Do not enter '5%' or '0.05'",
    component: renderField,
    validate: [required, number],
  },
];
