import React, { Fragment, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Field, FieldArray } from "redux-form";
import { motion } from "framer-motion";

const BudgetItemsTable = ({
  title = "",
  columns = [],
  fields = [],
  rowFields,
  nested = null,
}) => {
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          {fields && fields.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>{title}</strong>
                  </th>
                  {columns.map(({ name }) => (
                    <th>
                      <strong>{name}</strong>
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((item, index) => (
                  <BudgetRow
                    item={item}
                    index={index}
                    handleRemove={fields.remove}
                    rows={rowFields}
                    nested={nested}
                  />
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

const variants = {
  hover: {
    opacity: 1,
    scale: 1.1,
  },
  initial: {
    opacity: 0,
    scale: 1,
  },
};

const BudgetRow = ({ item, index, rows, handleRemove, nested }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <motion.tr
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <td>
          <strong>{index + 1}</strong>{" "}
        </td>
        {rows(item).map((row, index) => (
          <td key={index}>
            <Field {...row} />
          </td>
        ))}
        <td>
          <motion.i
            variants={variants}
            animate={isHovered ? "hover" : "initial"}
            className="fa fa-close text-danger fs-20 border-red-600 rounded-circle"
            role="button"
            onClick={() => handleRemove(index)}
          />
        </td>
      </motion.tr>
      {nested && <FieldArray {...nested(item)} />}
    </>
  );
};

export default BudgetItemsTable;
