import React from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../firebase";
import showResults from "./../ReduxForm/ShowResults";
import WizardForm from "./WizardForm";
import { useSelector } from "react-redux";

function Index() {
  const _auth = useSelector((state) => state.auth);

  const handleSubmit = async (values) => {
    showResults(values);
    const docRef = await addDoc(collection(db, "models"), {
      ...values,
      metadata: {
        created_at: new Date(),
        created_by: _auth.auth.localId,
        company_id: "<company-id-here>",
        visibility: "<visibility-here>",
      },
    });
    console.log("docRef", docRef.id);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">New Contract</h4>
          </div>
          <div className="card-body">
            <WizardForm onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Result</h4>
          </div>
          <div className="card-body">
            <pre className="" id="ReduxFormJSON"></pre>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Index;
