import todoReducers from "./Reducers";
import AuthReducer from "./AuthReducer";
import AccountReducer from "./AccountReducer";
import PostsReducer from "./PostsReducer";
import UserReducer from "./UserReducer";
import { reducer as ReduxFormReducer } from "redux-form";

import { combineReducers } from "redux";

const rootReducers = combineReducers({
  auth: AuthReducer,
  account: AccountReducer,
  posts: PostsReducer,
  form: ReduxFormReducer,
  user: UserReducer,
});

export default rootReducers;
