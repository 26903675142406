import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
//** Import Image */
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import PageTitle from "../../../layouts/PageTitle";
import { useAuth } from "../../../../context/AuthContext";

const infoIcon = (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="mr-2"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);

const AppProfile = (props) => {
  const _auth = useSelector((state) => state.auth);
  const [activeToggle, setActiveToggle] = useState("settings");
  const [sendMessage, setSendMessage] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [cameraModal, setCameraModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);

  const [replayModal, setReplayModal] = useState(false);

  const [auth, setAuth] = useState(_auth.auth);

  const { hasUserDocument } = useAuth();

  return (
    <Fragment>
      <PageTitle activeMenu="My Account" motherMenu="App" />

      {!hasUserDocument && (
        <Alert
          key="user-not-found"
          variant="info"
          className="alert-dismissible fade show"
        >
          {infoIcon}
          <strong>User not found</strong> Please update your data.
        </Alert>
      )}

      <div className="row">
        {/* <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                <div className="cover-photo"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">Mitchell C. Shay</h4>
                    <p>UX / UI Designer</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">hello@email.com</h4>
                    <p>Email</p>
                  </div>
                  <Dropdown className="dropdown ml-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-user-circle text-primary mr-2" />
                        View profile
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary mr-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary mr-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary mr-2" />
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("posts")}
                    >
                      <Link
                        to="#my-posts"
                        data-toggle="tab"
                        className={`nav-link ${
                          activeToggle === "posts" ? "active show" : ""
                        }`}
                      >
                        Posts
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("aboutMe")}
                    >
                      <Link
                        to="#about-me"
                        data-toggle="tab"
                        className={`nav-link ${
                          activeToggle === "aboutMe" ? "active show" : ""
                        }`}
                      >
                        About Me
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#profile-settings"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("settings")}
                        className={`nav-link ${
                          activeToggle === "settings" ? "active show" : ""
                        }`}
                      >
                        Settings
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="my-posts"
                      className={`tab-pane fade ${
                        activeToggle === "posts" ? "active show" : ""
                      }`}
                    >
                      <div className="my-post-content pt-3">
                        <div className="post-input">
                          <textarea
                            name="textarea"
                            id="textarea"
                            cols={30}
                            rows={5}
                            className="form-control bg-transparent"
                            placeholder="Please type what you want...."
                            defaultValue={""}
                          />
                          <Link
                            to="/settings"
                            className="btn btn-primary light px-3 mr-1"
                            data-toggle="modal"
                            data-target="#linkModal"
                            onClick={() => setLinkModal(true)}
                          >
                            <i className="fa fa-link m-0" />{" "}
                          </Link>
                          {/* Modal */}
                          <Modal
                            show={linkModal}
                            onHide={() => setLinkModal(false)}
                            className="modal fade post-input"
                            id="linkModal"
                            aria-hidden="true"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Social Links</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  onClick={() => setLinkModal(false)}
                                >
                                  <span>×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <Link
                                  className="btn-social mr-1 facebook"
                                  to="/settings"
                                >
                                  <i className="fa fa-facebook" />
                                </Link>
                                <Link
                                  className="btn-social mr-1 google-plus"
                                  to="/settings"
                                >
                                  {" "}
                                  <i className="fa fa-google-plus" />
                                </Link>
                                <Link
                                  className="btn-social mr-1 linkedin"
                                  to="/settings"
                                >
                                  <i className="fa fa-linkedin" />
                                </Link>
                                <Link
                                  className="btn-social mr-1 instagram"
                                  to="/settings"
                                >
                                  {" "}
                                  <i className="fa fa-instagram" />
                                </Link>
                                <Link
                                  className="btn-social mr-1 twitter"
                                  to="/settings"
                                >
                                  <i className="fa fa-twitter" />
                                </Link>
                                <Link
                                  className="btn-social mr-1 youtube"
                                  to="/settings"
                                >
                                  <i className="fa fa-youtube" />
                                </Link>
                                <Link
                                  className="btn-social whatsapp"
                                  to="/settings"
                                >
                                  <i className="fa fa-whatsapp" />
                                </Link>
                              </div>
                            </div>
                          </Modal>
                          <Link
                            to="/settings"
                            className="btn btn-primary light px-3 mr-1"
                            data-toggle="modal"
                            data-target="#cameraModal"
                            onClick={() => setCameraModal(true)}
                          >
                            <i className="fa fa-camera m-0" />{" "}
                          </Link>
                          {/* Modal */}
                          <Modal
                            show={cameraModal}
                            onHide={() => setCameraModal(false)}
                            className="modal fade"
                            id="cameraModal"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Upload images</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  onClick={() => setCameraModal(false)}
                                >
                                  <span>×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      Upload
                                    </span>
                                  </div>
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                    />
                                    <label className="custom-file-label">
                                      Choose file
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>
                          <Link
                            to="/settings"
                            className="btn btn-primary ml-1"
                            data-toggle="modal"
                            data-target="#postModal"
                            onClick={() => setPostModal(true)}
                          >
                            Post
                          </Link>
                          {/* Modal */}
                          <Modal
                            show={postModal}
                            onHide={() => setPostModal(false)}
                            className="modal fade"
                            id="postModal"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Post</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  onClick={() => setPostModal(false)}
                                >
                                  <span>×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <textarea
                                  name="textarea"
                                  id="textarea"
                                  cols={30}
                                  rows={5}
                                  className="form-control bg-transparent"
                                  placeholder="Please type what you want...."
                                  defaultValue={""}
                                />
                                <Link
                                  className="btn btn-primary btn-rounded mt-1"
                                  to="/settings"
                                >
                                  Post
                                </Link>
                              </div>
                            </div>
                          </Modal>
                        </div>

                        <div className="profile-uoloaded-post border-bottom-1 pb-5">
                          <img
                            src={profile08}
                            alt=""
                            className="img-fluid w-100"
                          />
                          <Link className="post-title" to="/post-details">
                            <h3 className="text-black">
                              Collection of textile samples lay spread
                            </h3>
                          </Link>
                          <p>
                            A wonderful serenity has take possession of my
                            entire soul like these sweet morning of spare which
                            enjoy whole heart.A wonderful serenity has take
                            possession of my entire soul like these sweet
                            morning of spare which enjoy whole heart.
                          </p>
                          <button className="btn btn-primary mr-2">
                            <span className="mr-2">
                              {" "}
                              <i className="fa fa-heart" />{" "}
                            </span>
                            Like
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setReplayModal(true)}
                          >
                            <span className="mr-2">
                              {" "}
                              <i className="fa fa-reply" />
                            </span>
                            Reply
                          </button>
                        </div>
                        <div className="profile-uoloaded-post border-bottom-1 pb-5">
                          <img
                            src={profile09}
                            alt=""
                            className="img-fluid w-100"
                          />
                          <Link className="post-title" to="/post-details">
                            <h3 className="text-black">
                              Collection of textile samples lay spread
                            </h3>
                          </Link>
                          <p>
                            A wonderful serenity has take possession of my
                            entire soul like these sweet morning of spare which
                            enjoy whole heart.A wonderful serenity has take
                            possession of my entire soul like these sweet
                            morning of spare which enjoy whole heart.
                          </p>
                          <button className="btn btn-primary mr-2">
                            <span className="mr-2">
                              {" "}
                              <i className="fa fa-heart" />{" "}
                            </span>
                            Like
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setReplayModal(true)}
                          >
                            <span className="mr-2">
                              {" "}
                              <i className="fa fa-reply" />
                            </span>
                            Reply
                          </button>
                        </div>
                        <div className="profile-uoloaded-post pb-3">
                          <img
                            src={profile08}
                            alt=""
                            className="img-fluid  w-100"
                          />
                          <Link className="post-title" to="/post-details">
                            <h3 className="text-black">
                              Collection of textile samples lay spread
                            </h3>
                          </Link>
                          <p>
                            A wonderful serenity has take possession of my
                            entire soul like these sweet morning of spare which
                            enjoy whole heart.A wonderful serenity has take
                            possession of my entire soul like these sweet
                            morning of spare which enjoy whole heart.
                          </p>
                          <button className="btn btn-primary mr-2">
                            <span className="mr-2">
                              <i className="fa fa-heart" />
                            </span>
                            Like
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setReplayModal(true)}
                          >
                            <span className="mr-2">
                              {" "}
                              <i className="fa fa-reply" />
                            </span>
                            Reply
                          </button>
                        </div>
                        {/* Modal */}
                        <Modal
                          show={replayModal}
                          onHide={() => setReplayModal(false)}
                          className="modal fade"
                          id="replyModal"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Post Reply</h5>
                              <button
                                type="button"
                                className="close"
                                onClick={() => setReplayModal(false)}
                              >
                                <span>&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <form>
                                <textarea className="form-control" rows="4">
                                  Message
                                </textarea>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger light"
                                onClick={() => setReplayModal(false)}
                              >
                                Close
                              </button>
                              <button type="button" className="btn btn-primary">
                                Reply
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div
                      id="about-me"
                      className={`tab-pane fade ${
                        activeToggle === "aboutMe" ? "active show" : ""
                      }`}
                    >
                      <div className="profile-about-me">
                        <div className="pt-4 border-bottom-1 pb-3">
                          <h4 className="text-primary">About Me</h4>
                          <p className="mb-2">
                            A wonderful serenity has taken possession of my
                            entire soul, like these sweet mornings of spring
                            which I enjoy with my whole heart. I am alone, and
                            feel the charm of existence was created for the
                            bliss of souls like mine.I am so happy, my dear
                            friend, so absorbed in the exquisite sense of mere
                            tranquil existence, that I neglect my talents.
                          </p>
                          <p>
                            A collection of textile samples lay spread out on
                            the table - Samsa was a travelling salesman - and
                            above it there hung a picture that he had recently
                            cut out of an illustrated magazine and housed in a
                            nice, gilded frame.
                          </p>
                        </div>
                      </div>
                      <div className="profile-skills mb-5">
                        <h4 className="text-primary mb-2">Skills</h4>
                        <Link
                          to="/settings"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          {" "}
                          Admin
                        </Link>
                        <Link
                          to="/settings"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          {" "}
                          Dashboard
                        </Link>
                        <Link
                          to="/settings"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Photoshop
                        </Link>
                        <Link
                          to="/settings"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Bootstrap
                        </Link>
                        <Link
                          to="/settings"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Responsive
                        </Link>
                        <Link
                          to="/settings"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Crypto
                        </Link>
                      </div>
                      <div className="profile-lang  mb-5">
                        <h4 className="text-primary mb-2">Language</h4>
                        <Link to="/settings" className="text-muted pr-3 f-s-16">
                          <i className="flag-icon flag-icon-us" />
                          English
                        </Link>
                        <Link to="/settings" className="text-muted pr-3 f-s-16">
                          <i className="flag-icon flag-icon-fr" />
                          French
                        </Link>
                        <Link to="/settings" className="text-muted pr-3 f-s-16">
                          <i className="flag-icon flag-icon-bd" />
                          Bangla
                        </Link>
                      </div>
                      <div className="profile-personal-info">
                        <h4 className="text-primary mb-4">
                          Personal Information
                        </h4>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Name<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>Mitchell C.Shay</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Email<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>example@examplel.com</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Availability<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>Full Time (Free Lancer)</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Age<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>27</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Location<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>Rosemont Avenue Melbourne, Florida</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Year Experience
                              <span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>07 Year Experiences</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="profile-settings"
                      className={`tab-pane fade ${
                        activeToggle === "settings" ? "active show" : ""
                      }`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <h4 className="text-primary">Account Settings</h4>
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Email</label>
                                <input
                                  disabled
                                  type="email"
                                  value={auth.email}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Password</label>
                                <input
                                  type="password"
                                  placeholder="Password"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Address</label>
                              <input
                                type="text"
                                placeholder="1234 Main St"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Address 2</label>
                              <input
                                type="text"
                                placeholder="Apartment, studio, or floor"
                                className="form-control"
                              />
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>City</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group col-md-4">
                                <label>State</label>
                                <select
                                  className="form-control"
                                  id="inputState"
                                  defaultValue="option-1"
                                >
                                  <option value="option-1">Choose...</option>
                                  <option value="option-2">Option 1</option>
                                  <option value="option-3">Option 2</option>
                                  <option value="option-4">Option 3</option>
                                </select>
                              </div>
                              <div className="form-group col-md-2">
                                <label>Zip</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="gridCheck"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="gridCheck"
                                >
                                  Check me out
                                </label>
                              </div>
                            </div>
                            <button className="btn btn-primary" type="submit">
                              Update
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
