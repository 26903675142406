import {
  USER_GET_CONFIRMED_ACTION,
  USER_GET_FAILED_ACTION,
} from "../actions/UserActions";

const initialState = {
  data: null,
  errorMessage: "",
  successMessage: "",
  loading: false,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case USER_GET_CONFIRMED_ACTION:
      return {
        ...state,
        data: action.payload,
        errorMessage: "",
        successMessage: "User Document Successfully Fetched",
        loading: false,
      };

    case USER_GET_FAILED_ACTION:
      return {
        ...state,
        data: null,
        errorMessage: "Error in fetching user document",
        successMessage: "",
        loading: false,
      };

    default:
      return state;
  }
}
