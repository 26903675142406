import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { renderField } from "./fields";
import { atLeastOne, number, required } from "./validation";

const renderItems = ({ fields, meta: { error, submitFailed } }) => (
  <ul>
    <li>
      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-link"
      >
        Add Item
      </button>
      {submitFailed && error && <p className="fs-12 text-danger">{error}</p>}
    </li>
    {fields.map((item, index) => (
      <li key={index}>
        <h4>
          Disbursement #{index + 1}{" "}
          <button
            type="button"
            title="Remove Member"
            className="btn btn-danger rounded px-2 py-1"
            onClick={() => fields.remove(index)}
          >
            Remove
          </button>
        </h4>
        <div className="ml-4">
          <Field
            name={`${item}.months`}
            type="number"
            component={renderField}
            label={`Please enter the month of disbursement # ${
              index + 1
            } below`}
            helper="Just enter the month number - nothing else; i.e. for '8 months' simply enter '8'"
            placeholder="Enter number of months"
            validate={[required, number]}
            parse={(value) => +value}
          />
          <Field
            name={`${item}.amount`}
            type="number"
            component={renderField}
            label={`Please enter the dollar amount of disbursement # ${
              index + 1
            } below`}
            helper="Just enter digits - nothing else; i.e. for $5,000 simply type '5000' - do not add commas or dollar sign"
            placeholder="Enter amount"
            validate={[required, number]}
            parse={(value) => +value}
          />
        </div>
      </li>
    ))}
  </ul>
);

const SeniorDebtDisbursementTypeMulti = (props) => {
  const { handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        <div className="col-sm-12">
          <p className="font-w600">Add Disbursements below</p>
          <code className="font-w600">
            DEV NOTE!!!: AT THIS POINT, WE SHOULD KNOW THE TOTAL AMOUNT TO BE
            DISBURSED
            (https://vast-ravine-86906.herokuapp.com/yes_multi_term_disbursement)
          </code>

          <FieldArray
            name="financing.senior_debt.disbursement.terms"
            validate={atLeastOne}
            component={renderItems}
          />
        </div>
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(SeniorDebtDisbursementTypeMulti);
