import {
  createUser,
  formatError,
  formatErrorString,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import * as types from "./types";

export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

// const dummyToken = {
//     "auth": {
//         "kind": "identitytoolkit#VerifyPasswordResponse",
//         "localId": "qmt6dRyipIad8UCc0QpMV2MENSy1",
//         "email": "demo@example.com",
//         "displayName": "",
//         "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkYzBlNmRmOTgyN2EwMjA2MWU4MmY0NWI0ODQwMGQwZDViMjgyYzAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVhY3QtY291cnNlLWI3OThlIiwiYXVkIjoicmVhY3QtY291cnNlLWI3OThlIiwiYXV0aF90aW1lIjoxNjQ2NjE2MDE1LCJ1c2VyX2lkIjoicW10NmRSeWlwSWFkOFVDYzBRcE1WMk1FTlN5MSIsInN1YiI6InFtdDZkUnlpcElhZDhVQ2MwUXBNVjJNRU5TeTEiLCJpYXQiOjE2NDY2MTYwMTUsImV4cCI6MTY0NjYxOTYxNSwiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRlbW9AZXhhbXBsZS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.mTxsA4o3L4B0E07zOm6wqJonjlogidbgpHAEbCex2JrYPXphYqzXWFOZ5ZT8GoBUaWPQx2h9W3McbsiUh5Ulv26XbBzXRM6g3cMeh_sn8qwxI9Ys5o4CFwVfkyyPhxrIEphF1tb0TRTZS-ASYRgHlwRwwRo06gl5T4RvJIG_bCTs5alxiENe4RsBi16XLqwWkIDJTxE5WA5TPBX1a3VrPjZsCluRS-vouJn2u0XpyTmQ1FdQl6LEeHgqtrxxREj2IDVw0J7C0koeDI2FTIErQtJH5-cAzRZKWRKSEvXHPEBHMmUptLg8WGMfZzPPWs6ENKGLUTkLjrHS_UiwiI0LBw",
//         "registered": true,
//         "refreshToken": "AIwUaOnip3wVaRH-7DYhC_2t5l3Or7LKk-bnBSSDq-adzWLASAYjkYcDnGnNgM1EbVBrCMgf20D5AN-CVR9v8XIPD0Q-SRBZBx0JJNIZXyxqBTWoPPimbC-et6_5_8ROMFd_KIFCUURVgv77dbYiDrcsLWHZrT-FDzFjGyks98MJBYeUvYnsiT90fBwpd45PBnMiNkSrLlUNR2hxLuRIkgvvo-nsDaSkcQ",
//         "expiresIn": 3601,
//         "expireDate": "2022-03-09T02:20:15.296Z"
//       }
// }

export async function signupService({ email, password, userData }) {
  try {
    const authRes = await signUp(email, password);

    let token = { auth: authRes._tokenResponse };
    const expiry = parseInt(token.auth.expiresIn);
    const today = new Date();
    today.setSeconds(today.getMinutes() + expiry);
    token.auth.expireDate = new Date(today + expiry);
    saveTokenInLocalStorage(token);

    try {
      await createUser(authRes.user.uid, userData);
      return token;
    } catch (error) {
      const errorMessage = formatErrorString(error);
      return errorMessage;
    }
  } catch (error) {
    const errorMessage = formatErrorString(error);
    return errorMessage;
  }
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export async function loginService({ email, password }) {
  try {
    const authRes = await login(email, password);

    let token = { auth: authRes._tokenResponse };
    const expiry = parseInt(token.auth.expiresIn);
    const today = new Date();
    today.setSeconds(today.getMinutes() + expiry);
    token.auth.expireDate = new Date(today + expiry);
    saveTokenInLocalStorage(token);
    return token;
  } catch (error) {
    const errorMessage = formatErrorString(error);
    return errorMessage;
  }
}

export function signupAction(payload) {
  return {
    type: types.REGISTER,
    payload,
  };
}

export function loginAction(payload) {
  return {
    type: types.LOG_IN,
    payload,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: types.LOG_IN_SUCCESS,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
