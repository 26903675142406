import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

import PropertyInfo from "./1_PropertyInfo";
import AnalysisTerm from "./2_AnalysisTerm";
import PurchasePrice from "./3_PurchasePrice";
import BudgetType from "./4_BudgetType";
import BudgetTypeTotalCost from "./4_BudgetType_TotalCost";
import BudgetTypeSimpleBudget from "./4_BudgetType_SimpleBudget";
import BudgetTypeItemized from "./4_BudgetType_Itemized";
import SeniorDebt from "./5_SeniorDebt";
import SeniorDebtSizingType from "./5_SeniorDebt_SizingType";
import SeniorDebtSizingTypeDA from "./5_SeniorDebt_SizingType_DA";
import SeniorDebtSizingTypeLTV from "./5_SeniorDebt_SizingType_LTV";
import SeniorDebtAssumptions from "./5_SeniorDebt_Assumptions";
import SeniorDebtDisbursementType from "./5_SeniorDebt_DisbursementType";
import SeniorDebtDisbursementTypeMulti from "./5_SeniorDebt_DisbursementType_Multi";
import IncomeEntry from "./6_IncomeEntry";
import IncomeEntryGross from "./6_IncomeEntry_Gross";
import IncomeEntryUnit from "./6_IncomeEntry_Unit";
import IncomeInflation from "./6_Income_Inflation";
import OtherIncome from "./7_OtherIncome";
import OtherIncomeItems from "./7_OtherIncome_Items";
import Expenses from "./8_Expenses";
import ExpensesItems from "./8_Expenses_Items";
import Refinance from "./9_Refinance";
import RefinanceItems from "./9_Refinance_Items";
import RefinanceDebtAssumptions from "./9_Refinance_DebtAssumptions";
import Disposition from "./10_Disposition";
import Waterfall from "./11_Waterfall";
import WaterfallItems from "./11_Waterfall_Items";

import { useSelector } from "react-redux";

const WizardForm = ({ onSubmit }) => {
  const [page, setPage] = useState("PropertyInfo");
  const values = useSelector((state) => state.form.wizard?.values);

  const wizard = useMemo(
    () => [
      {
        pageName: "PropertyInfo",
        Component: PropertyInfo,
        previousPage: () => {},
        onSubmit: () => setPage("AnalysisTerm"),
      },
      {
        pageName: "AnalysisTerm",
        Component: AnalysisTerm,
        previousPage: () => setPage("PropertyInfo"),
        onSubmit: () => setPage("PurchasePrice"),
      },
      {
        pageName: "PurchasePrice",
        Component: PurchasePrice,
        previousPage: () => setPage("AnalysisTerm"),
        onSubmit: () => setPage("BudgetType"),
      },
      {
        pageName: "BudgetType",
        Component: BudgetType,
        previousPage: () => setPage("PurchasePrice"),
        onSubmit: () => {
          if (values?.budget?.type === "total_cost") {
            setPage("BudgetTypeTotalCost");
          } else if (values?.budget?.type === "simple_budget") {
            setPage("BudgetTypeSimpleBudget");
          } else {
            setPage("BudgetTypeItemized");
          }
        },
      },
      {
        pageName: "BudgetTypeTotalCost",
        Component: BudgetTypeTotalCost,
        previousPage: () => setPage("BudgetType"),
        onSubmit: () => setPage("SeniorDebt"),
      },
      {
        pageName: "BudgetTypeSimpleBudget",
        Component: BudgetTypeSimpleBudget,
        previousPage: () => setPage("BudgetType"),
        onSubmit: () => setPage("SeniorDebt"),
      },
      {
        pageName: "BudgetTypeItemized",
        Component: BudgetTypeItemized,
        previousPage: () => setPage("BudgetType"),
        onSubmit: () => setPage("SeniorDebt"),
      },
      {
        pageName: "SeniorDebt",
        Component: SeniorDebt,
        previousPage: () => setPage("BudgetType"),
        onSubmit: () => {
          if (values?.financing?.type === "has_senior_debt") {
            setPage("SeniorDebtSizingType");
          } else {
            setPage("IncomeEntry");
          }
        },
      },
      {
        pageName: "SeniorDebtSizingType",
        Component: SeniorDebtSizingType,
        previousPage: () => setPage("SeniorDebt"),
        onSubmit: () => {
          if (values?.financing?.senior_debt?.sizing_type === "dollar_amt") {
            setPage("SeniorDebtSizingTypeDA");
          } else if (
            values?.financing?.senior_debt?.sizing_type === "loan_to_value"
          ) {
            setPage("SeniorDebtSizingTypeLTV");
          } else {
            setPage("SeniorDebtSizingTypeDA");
          }
        },
      },
      {
        pageName: "SeniorDebtSizingTypeDA",
        Component: SeniorDebtSizingTypeDA,
        previousPage: () => setPage("SeniorDebtSizingType"),
        onSubmit: () => setPage("SeniorDebtAssumptions"),
      },
      {
        pageName: "SeniorDebtSizingTypeLTV",
        Component: SeniorDebtSizingTypeLTV,
        previousPage: () => setPage("SeniorDebtSizingType"),
        onSubmit: () => setPage("SeniorDebtAssumptions"),
      },
      {
        pageName: "SeniorDebtAssumptions",
        Component: SeniorDebtAssumptions,
        previousPage: () => setPage("SeniorDebtSizingType"),
        onSubmit: () => setPage("SeniorDebtDisbursementType"),
      },
      {
        pageName: "SeniorDebtDisbursementType",
        Component: SeniorDebtDisbursementType,
        previousPage: () => setPage("SeniorDebtAssumptions"),
        onSubmit: () => {
          if (
            values?.financing?.senior_debt?.disbursement?.type === "multiple"
          ) {
            setPage("SeniorDebtDisbursementTypeMulti");
          } else {
            setPage("IncomeEntry");
          }
        },
      },
      {
        pageName: "SeniorDebtDisbursementTypeMulti",
        Component: SeniorDebtDisbursementTypeMulti,
        previousPage: () => setPage("SeniorDebtDisbursementType"),
        onSubmit: () => setPage("IncomeEntry"),
      },
      {
        pageName: "IncomeEntry",
        Component: IncomeEntry,
        previousPage: () => setPage("SeniorDebt"),
        onSubmit: () => {
          if (values?.income?.type === "gross_annual_income") {
            setPage("IncomeEntryGross");
          } else {
            setPage("IncomeEntryUnit");
          }
        },
      },
      {
        pageName: "IncomeEntryGross",
        Component: IncomeEntryGross,
        previousPage: () => setPage("IncomeEntry"),
        onSubmit: () => setPage("IncomeInflation"),
      },
      {
        pageName: "IncomeEntryUnit",
        Component: IncomeEntryUnit,
        previousPage: () => setPage("IncomeEntry"),
        onSubmit: () => setPage("IncomeInflation"),
      },
      {
        pageName: "IncomeInflation",
        Component: IncomeInflation,
        previousPage: () => setPage("IncomeEntry"),
        onSubmit: () => setPage("OtherIncome"),
      },
      {
        pageName: "OtherIncome",
        Component: OtherIncome,
        previousPage: () => setPage("IncomeInflation"),
        onSubmit: () => {
          if (values?.income?.other_income === "has_other_income") {
            setPage("OtherIncomeItems");
          } else {
            setPage("Expenses");
          }
        },
      },
      {
        pageName: "OtherIncomeItems",
        Component: OtherIncomeItems,
        previousPage: () => setPage("OtherIncome"),
        onSubmit: () => setPage("Expenses"),
      },
      {
        pageName: "Expenses",
        Component: Expenses,
        previousPage: () => setPage("OtherIncome"),
        onSubmit: () => {
          if (values?.expenses?.type === "has_expenses") {
            setPage("ExpensesItems");
          } else {
            setPage("Refinance");
          }
        },
      },
      {
        pageName: "ExpensesItems",
        Component: ExpensesItems,
        previousPage: () => setPage("Expenses"),
        onSubmit: () => setPage("Refinance"),
      },
      {
        pageName: "Refinance",
        Component: Refinance,
        previousPage: () => setPage("Expenses"),
        onSubmit: () => {
          if (values?.financing?.refinance?.type === "has_refinance") {
            setPage("RefinanceItems");
          } else {
            setPage("Disposition");
          }
        },
      },
      {
        pageName: "RefinanceItems",
        Component: RefinanceItems,
        previousPage: () => setPage("Refinance"),
        onSubmit: () => setPage("RefinanceDebtAssumptions"),
      },
      {
        pageName: "RefinanceDebtAssumptions",
        Component: RefinanceDebtAssumptions,
        previousPage: () => setPage("RefinanceItems"),
        onSubmit: () => setPage("Disposition"),
      },
      {
        pageName: "Disposition",
        Component: Disposition,
        previousPage: () => setPage("Refinance"),
        onSubmit: () => setPage("Waterfall"),
      },
      {
        pageName: "Waterfall",
        Component: Waterfall,
        previousPage: () => setPage("Disposition"),
        onSubmit: (props) => {
          if (values?.cash_flow?.type === "has_waterfall") {
            setPage("WaterfallItems");
          } else {
            onSubmit(props);
          }
        },
      },
      {
        pageName: "WaterfallItems",
        Component: WaterfallItems,
        previousPage: () => setPage("Waterfall"),
        onSubmit: onSubmit,
      },
    ],
    [values]
  );

  return (
    <div className="">
      {wizard.map(({ pageName, Component, onSubmit, previousPage }) => (
        <AnimatePresence key={pageName}>
          {page === pageName && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, display: "none" }}
            >
              <Component onSubmit={onSubmit} previousPage={previousPage} />
            </motion.div>
          )}
        </AnimatePresence>
      ))}
    </div>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WizardForm;
