export const INIT_APP = "INIT_APP";

export const REGISTER = "REGISTER";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const REGISTER_FAILED = "REGISTER_FAILED";

export const LOG_IN = "LOG_IN";

export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";

export const LOG_IN_FAILED = "LOG_IN_FAILED";

export const LOG_OUT = "LOG_OUT";

export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";

export const LOG_OUT_FAILED = "LOG_OUT_FAILED";

export const NEEDS_VERIFICATION = "NEEDS_VERIFICATION";

export const VERIFY = "VERIFY";

export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const VERIFY_FAILED = "VERIFY_FAILED";

export const PROCESS_QUEUE = "PROCESS_QUEUE";

export const PUSH_MESSAGE = "PUSH_MESSAGE";

export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const GET_USER_DATA = "GET_USER_DATA";

export const GET_USER_DATA_START = "GET_USER_DATA_START";

export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";

export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";
