import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { expensesFields, renderError } from "./fields";

const Expenses = (props) => {
  const { handleSubmit, previousPage, change } = props;

  const values = useSelector((state) => state.form.wizard?.values);

  const onSubmit = (props) => {
    if (values?.expenses?.type === "no_expenses") {
      change("expenses.expenses", null);
    }

    handleSubmit(props);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="validate-redux-form row">
        {expensesFields.map(
          ({ name, customClass, label, options, validate, ...field }) => (
            <div className={customClass ?? "col-sm-12"} key={name}>
              <p className="font-w600">{label}</p>
              {options.map((option) => (
                <Field
                  {...field}
                  name={name}
                  label={option.label}
                  value={option.value}
                />
              ))}
              <Field name={name} validate={validate} component={renderError} />
            </div>
          )
        )}
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(Expenses);
