import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { propertyInfoFields } from "./fields";

const PropertyInfo = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        {propertyInfoFields.map(
          ({ name, customClass, type, value, ...field }) => (
            <div className={customClass ?? "col-sm-12"} key={name}>
              <Field
                {...field}
                name={name}
                type={type ?? "text"}
                parse={type === "number" ? (value) => +value : null}
              />
            </div>
          )
        )}

        <div className="col-sm-12 mt-4 ">
          <button type="submit" className="next btn btn-primary">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  connect((state, props) => {
    const values = state.form.wizard?.values;

    // TODO: @dev: maybe get initial values from user data?

    return {
      initialValues: values ?? {
        address: {
          city: "",
          state: "",
          street: "",
          zip: "",
        },
        building_attributes: {
          zoning: "",
          year_built: "",
          stories: "",
          dimensions: {
            square_feet: "",
            length: "",
            width: "",
          },
        },
        lot: {
          square_feet: "",
          length: "",
          width: "",
        },
      },
    };
  }),
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(PropertyInfo);
