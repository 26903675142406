// import swal from "sweetalert";
// import {} from "../store/actions/DatabaseActions";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export async function getUserById(id) {
  debugger;
  const docRef = doc(db, "users", id);
  debugger;
  const docSnap = await getDoc(docRef);
  debugger;
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    alert("error, user not found in db... alert dev todo")
  } 
}
