const REQUIRED = "Required";
const NUMBER = "Must be a number";
const AT_LEAST_ONE = "At least one item must be entered";

export const required = (value) => {
  return value ? undefined : REQUIRED;
};
export const number = (value) => {
  return value && isNaN(Number(value)) ? NUMBER : undefined;
};

export const atLeastOne = (value) => {
  return !value || !value?.length ? AT_LEAST_ONE : undefined;
};

// Sample validation function for redux for 'Sync validation'
// 4_BudgetType_SimpleBudget
// if (!values?.budget?.simple_budget || !values.budget.simple_budget.length) {
//   errors.budget = {
//     ...(errors.budget || {}),
//     simple_budget: {
//       _error: AT_LEAST_ONE,
//     },
//   };
// } else {
//   const simpleBudgetArrayErrors = [];
//   values.budget.simple_budget.forEach((simpleBudgetItem, index) => {
//     const simpleBudgetItemErrors = {};
//     if (!simpleBudgetItem || !simpleBudgetItem.itemName) {
//       simpleBudgetItemErrors.itemName = REQUIRED;
//       simpleBudgetArrayErrors[index] = simpleBudgetItemErrors;
//     }
//     if (!simpleBudgetItem || !simpleBudgetItem.itemCost) {
//       simpleBudgetItemErrors.itemCost = REQUIRED;
//       simpleBudgetArrayErrors[index] = simpleBudgetItemErrors;
//     } else if (isNaN(Number(simpleBudgetItem.itemCost))) {
//       simpleBudgetItemErrors.itemCost = NUMBER;
//       simpleBudgetArrayErrors[index] = simpleBudgetItemErrors;
//     }
//   });
//   if (simpleBudgetArrayErrors.length) {
//     errors.budget = {
//       ...(errors.budget || {}),
//       simple_budget: simpleBudgetArrayErrors,
//     };
//   }
// }

// 4_BudgetType_Itemized
// if (
//   !values?.budget?.itemized_budget ||
//   !values.budget.itemized_budget.length
// ) {
//   errors.budget = {
//     ...(errors.budget || {}),
//     itemized_budget: {
//       _error: AT_LEAST_ONE,
//     },
//   };
// } else {
//   const itemizedBudgetArrayErrors = [];
//   values.budget.itemized_budget.forEach((budgetItem, index) => {
//     const budgetItemErrors = {};
//     if (!budgetItem || !budgetItem.name) {
//       budgetItemErrors.name = REQUIRED;
//       itemizedBudgetArrayErrors[index] = budgetItemErrors;
//     }
//     if (
//       !budgetItem ||
//       !budgetItem.subcategories ||
//       !budgetItem.subcategories.length
//     ) {
//       budgetItemErrors.subcategories = {
//         _error: AT_LEAST_ONE,
//       };
//       itemizedBudgetArrayErrors[index] = budgetItemErrors;
//     } else {
//       const budgetItemErrors = [];
//       budgetItem.subcategories.forEach((item, itemIndex) => {
//         const subcatItemErrors = {};
//         if (!item || !item.item_name) {
//           subcatItemErrors.item_name = REQUIRED;
//           budgetItemErrors[itemIndex] = subcatItemErrors;
//         }
//         if (!item || !item.item_cost) {
//           subcatItemErrors.item_cost = REQUIRED;
//           budgetItemErrors[itemIndex] = subcatItemErrors;
//         } else if (isNaN(Number(item.item_cost))) {
//           subcatItemErrors.item_cost = NUMBER;
//           budgetItemErrors[itemIndex] = subcatItemErrors;
//         }
//       });
//       if (budgetItemErrors.length) {
//         budgetItemErrors.subcategories = budgetItemErrors;
//         itemizedBudgetArrayErrors[index] = budgetItemErrors;
//       }
//     }
//   });
//   if (itemizedBudgetArrayErrors.length) {
//     errors.budget = {
//       ...(errors.budget || {}),
//       itemized_budget: itemizedBudgetArrayErrors,
//     };
//   }
// }
