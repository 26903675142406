import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { renderField } from "./fields";
import { atLeastOne, number, required } from "./validation";

const renderItems = ({ fields, meta: { error, submitFailed } }) => (
  <ul>
    <li>
      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-link"
      >
        Add Item
      </button>
      {submitFailed && error && <p className="fs-12 text-danger">{error}</p>}
    </li>
    {fields.map((item, index) => (
      <li key={index}>
        <h4>
          Expense type #{index + 1}{" "}
          <button
            type="button"
            title="Remove"
            className="btn btn-danger rounded px-2 py-1"
            onClick={() => fields.remove(index)}
          >
            Remove
          </button>
        </h4>
        <div className="ml-4">
          <Field
            name={`${item}.type`}
            type="text"
            component={renderField}
            label="Expense Type"
            placeholder="i.e. real estate taxes"
            validate={required}
          />
          <Field
            name={`${item}.annual_expense`}
            type="number"
            component={renderField}
            label="In-place annual expense"
            placeholder="Just enter digits - i.e. for $5,000 simply type '5000' - do not add commas or dollar sign"
            validate={[required, number]}
            parse={(value) => +value}
          />
          <Field
            name={`${item}.inflation_rate`}
            type="number"
            component={renderField}
            label="Inflation rate"
            placeholder="Just enter digits - i.e. for 3% simply type '3' - not '3%' or '0.03'"
            validate={[required, number]}
            parse={(value) => +value}
          />
        </div>
      </li>
    ))}
  </ul>
);

const ExpensesItems = (props) => {
  const { handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        <div className="col-sm-12">
          <p className="font-w600">Add expenses below</p>

          <FieldArray
            name="expenses.expenses"
            validate={atLeastOne}
            component={renderItems}
          />
        </div>
        <div className="col-md-12">
          <p className="fs-14">
            The expense amount entered above is the in-place expense amount and
            will be inflated by the inflation rate for Y1. If you would like the
            amount above to be the Y1 expense amount, discount it by the
            inflation rate you intend to use.
          </p>
        </div>
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(ExpensesItems);
