import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAutoLogin } from "../services/AuthService";
import { initializeApp } from "../store/actions/GeneralActions";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ props, children }) => {
  const dispatch = useDispatch();
  const _auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [hasUserDocument, setHasUserDocument] = useState(true);

  const checkIfAuthenticated = useCallback(() => {
    if (!!_auth?.auth?.idToken) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [_auth?.auth]);

  const checkUserDocument = useCallback(() => {
    if (!user.data) {
      setHasUserDocument(false);
    } else {
      setHasUserDocument(true);
    }
  }, [user, props.history.location]);

  // const checkIfUserDocumentExists = useCallback(() => {
  //   if (authenticated && props.history.location.pathname !== "/settings") {
  //     if (!user.data) {
  //       props.history.push("/settings");
  //     }
  //   }
  // }, [authenticated, props.history.location, user.data]);

  useEffect(() => {
    dispatch(initializeApp());
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);

  // check if user exists
  useEffect(() => {
    checkIfAuthenticated();
  }, [checkIfAuthenticated]);

  // check if user document exists
  useEffect(() => {
    checkUserDocument();
  }, [checkUserDocument]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      hasUserDocument,
    }),
    [isAuthenticated, hasUserDocument]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
