import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { budgetTypeFields, renderError } from "./fields";

const BudgetType = (props) => {
  const { handleSubmit, previousPage, change } = props;

  const values = useSelector((state) => state.form.wizard?.values);

  const onSubmit = (props) => {
    switch (values?.budget?.type) {
      case "total_cost":
        change("budget.simple_budget", null);
        change("budget.itemized_budget", null);
        break;

      case "simple_budget":
        change("budget.total_cost", null);
        change("budget.itemized_budget", null);
        break;

      case "itemized_budget":
        change("budget.total_cost", null);
        change("budget.simple_budget", null);
        break;

      default:
        change("budget.total_cost", null);
        change("budget.simple_budget", null);
        change("budget.itemized_budget", null);
    }

    handleSubmit(props);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="validate-redux-form row">
        {budgetTypeFields.map(
          ({ name, customClass, label, options, validate, ...field }) => {
            // if (field.type === "radio") {
            return (
              <div className={customClass ?? "col-sm-12"} key={name}>
                <p className="font-w600">{label}</p>
                {options.map((option, index) => (
                  <Field
                    {...field}
                    name={name}
                    label={option.label}
                    value={option.value}
                    noMargin={
                      index === options.length - 1 &&
                      values?.budget &&
                      !values.budget.type
                    }
                  />
                ))}
                <Field
                  name={name}
                  validate={validate}
                  component={renderError}
                />
              </div>
            );
            // }

            // let referenceField = field.referenceField.split(".");
            // let referenceValue = values?.[referenceField[0]]?.[referenceField[1]];

            // let val;
            // for (let i = 0; i < referenceField.length; i++) {
            //   if (i === 0) {
            //     val = values?.[referenceField[i]];
            //   } else {
            //     val = val?.[referenceField[i]];
            //   }
            // }

            // return (
            //   <div
            //     className={field.customClass ?? "col-sm-12"}
            //     key={field.name + index}
            //     // style={{
            //     //   display:
            //     //     referenceValue === field.referenceAnswer ? "block" : "none",
            //     // }}
            //   >
            //     <Field
            //       name={field.name}
            //       type={field.type ?? "text"}
            //       component={field.component}
            //       label={field.label}
            //       helper={field.helper}
            //       placeholder={field.placeholder}
            //     />
            //   </div>
            // );
          }
        )}
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(BudgetType);
