import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { getUserAction } from "../store/actions/UserActions";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";

export function signUp(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function createUser(userId, data) {
  return setDoc(doc(db, "users", userId), data);
}

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function formatError(error) {
  switch (error.code) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "auth/user-not-found":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "auth/wrong-password":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "??"; // TODO: find a more robust solution
  }
}

export function formatErrorString(error) {
  switch (error.code) {
    case "auth/email-already-in-use":
      //return 'Email not found';
      return "Email already in use";
    case "auth/user-not-found":
      //return 'Email not found';
      return "Email not found";
    case "auth/wrong-password":
      //return 'Invalid Password';
      return "Invalid Password";
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "??"; // TODO: find a more robust solution
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

// export function checkAutoLogin(dispatch, history) {
//     const tokenDetailsString = localStorage.getItem('userDetails');
//     let tokenDetails = '';
//     if (!tokenDetailsString) {
//         dispatch(logout(history));
//         return;
//     }

//     tokenDetails = JSON.parse(tokenDetailsString);
//     let expireDate = new Date(tokenDetails.auth.expireDate);
//     let todaysDate = new Date();

//     debugger;
//     if (todaysDate > expireDate) {
//         dispatch(logout(history));
//         return;
//     }
//     dispatch(loginConfirmedAction(tokenDetails));
//     debugger;
//     dispatch(getUserAction(tokenDetails));

//     const timer = expireDate.getTime() - todaysDate.getTime();
//     debugger;
//     // runLogoutTimer(dispatch, timer, history);
// }

export function getToken() {
  const tokenDetailsString = localStorage.getItem("userDetails");
  if (!tokenDetailsString) {
    return null;
  }

  return JSON.parse(tokenDetailsString);
}

export function expired(token) {
  const expireDate = new Date(token.auth.expireDate);
  const todaysDate = new Date();
  if (todaysDate > expireDate) {
    return true;
  }
}
export function checkAutoLogin(dispatch, history) {
  const token = getToken();
  if (!token) {
    history.push("/login");
    return;
  }

  if (expired(token)) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(token));
  dispatch(getUserAction(token));
}
