import React from "react";
import { FieldArray, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { renderField } from "./fields";
import { atLeastOne, number, required } from "./validation";
import BudgetItems from "../../table/BudgetItems.js";

const renderItems = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <div>
      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-primary rounded light mr-3"
      >
        + ADD
      </button>
      {submitFailed && error && <p className="fs-12 text-danger">{error}</p>}
    </div>
    <BudgetItems
      title="Item"
      columns={[{ name: "Name" }, { name: "Cost" }]}
      fields={fields}
      rowFields={(item) => [
        {
          name: `${item}.item_name`,
          type: "text",
          component: renderField,
          validate: required,
        },
        {
          name: `${item}.item_cost`,
          type: "number",
          component: renderField,
          validate: [required, number],
          parse: (value) => +value,
        },
      ]}
    />
  </div>
);

const BudgetTypeSimpleBudget = (props) => {
  const { handleSubmit, previousPage } = props;

  const onSubmit = (e) => {
    console.log(e);
    handleSubmit(e);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="validate-redux-form row">
        <div className="col-sm-12">
          <p className="font-w600">Add Budget Items below</p>
          <FieldArray
            name="budget.simple_budget"
            validate={atLeastOne}
            component={renderItems}
          />
          {/* <BudgetItems
            columns={[{ name: "Name" }, { name: "Cost" }]}
            data={data}
          /> */}
        </div>
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(BudgetTypeSimpleBudget);
