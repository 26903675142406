import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { renderField } from "./fields";
import { atLeastOne, number, required } from "./validation";

const renderProForma = ({ fields, meta: { error } }) => (
  <ul>
    <li>
      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-link"
      >
        Add Unit
      </button>
    </li>
    {fields.map((proforma, index) => (
      <li key={index}>
        <h5>
          Unit #{index + 1}{" "}
          <button
            type="button"
            title="Remove"
            className="btn btn-danger rounded px-2 py-1"
            onClick={() => fields.remove(index)}
          >
            Remove
          </button>
        </h5>
        <div className="ml-4">
          <Field
            name={`${proforma}.updated_rent`}
            type="text"
            component={renderField}
            label="Monthly proforma rent"
            placeholder="The in-place monthly rent"
            validate={required}
          />
          <Field
            name={`${proforma}.units_to_update`}
            type="number"
            component={renderField}
            label="Total Units Projected to be Turned Over"
            placeholder="0"
            validate={[required, number]}
            parse={(value) => +value}
          />
          <Field
            name={`${proforma}.starting_month`}
            type="number"
            component={renderField}
            label="Starting in month #"
            placeholder="0"
            validate={[required, number]}
            parse={(value) => +value}
          />
          <Field
            name={`${proforma}.units_updated_per_term`}
            type="number"
            component={renderField}
            label="At a rate of"
            helper="Enter here the numbe of units you would like to turn over in each tranche"
            placeholder="0"
            validate={[required, number]}
            parse={(value) => +value}
          />
          <Field
            name={`${proforma}.update_term_months`}
            type="number"
            component={renderField}
            label="Every"
            helper="Enter here the amount of months between each tranche of turnover"
            placeholder="0"
            validate={[required, number]}
            parse={(value) => +value}
          />
        </div>
      </li>
    ))}
    {error && <p className="fs-12 text-danger">{error}</p>}
  </ul>
);

const renderItems = ({ fields, meta: { error, submitFailed } }) => (
  <ul>
    <li>
      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-link"
      >
        Add Item
      </button>
      {submitFailed && error && <p className="fs-12 text-danger">{error}</p>}
    </li>
    {fields.map((item, index) => (
      <li key={index}>
        <h4>
          Unit type #{index + 1}{" "}
          <button
            type="button"
            title="Remove"
            className="btn btn-danger rounded px-2 py-1"
            onClick={() => fields.remove(index)}
          >
            Remove
          </button>
        </h4>
        <div className="ml-4">
          <Field
            name={`${item}.type`}
            type="text"
            component={renderField}
            label="Unit Type"
            placeholder="i.e. 1 BR"
            validate={required}
          />
          <Field
            name={`${item}.square_feet`}
            type="number"
            component={renderField}
            label="Square Feet"
            placeholder="Just enter digits - i.e. for 800SF simply type '800' - not '800 SF'"
            validate={[required, number]}
            parse={(value) => +value}
          />
          <Field
            name={`${item}.monthly_income`}
            type="number"
            component={renderField}
            label="Monthly rental income"
            placeholder="Just enter digits - i.e. for $5,000 simply type '5000' - do not add commas or dollar sign"
            validate={[required, number]}
            parse={(value) => +value}
          />
          {/* <Field
            name={`${item}.amount`}
            type="number"
            component={renderField}
            label="Amount of units"
            placeholder="Just enter digits - i.e. for 5 units simply type '5' - not '5 units'"
          /> */}
          <FieldArray
            name={`${item}.units`}
            validate={atLeastOne}
            component={renderProForma}
          />
        </div>
      </li>
    ))}
  </ul>
);

const IncomeEntryUnit = (props) => {
  const { handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        <div className="col-sm-12">
          <p className="font-w600">Add Unit types below</p>
          <p className="fs-14">
            Make sure not to enter a unit type more than once. For example, if
            you already entered 1 BR units and you want to enter a different
            type of 1 BR unit, consider distinguishing it. i.e. "1 BR and 1
            Bath".
          </p>
          <FieldArray
            name="income.unit_mix"
            validate={atLeastOne}
            component={renderItems}
          />
        </div>
        <div className="col-md-12">
          <p className="fs-14">
            The income entered above is the in-place income and will be inflated
            by the inflation rate for Y1. If you would like the amount above to
            be the Y1 income, discount it by the inflation rate you intend to
            use.
          </p>
        </div>
        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(IncomeEntryUnit);
