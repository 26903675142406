import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { refinanceDebtAssumptionsFields } from "./fields";

const RefinanceDebtAssumptions = (props) => {
  const { handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        {refinanceDebtAssumptionsFields.map(
          ({ name, customClass, type, ...field }) => (
            <div className={customClass ?? "col-sm-12"} key={name}>
              <Field
                {...field}
                name={name}
                type={type ?? "text"}
                parse={type === "number" ? (value) => +value : null}
              />
            </div>
          )
        )}

        <div className="col-md-12">
          <button
            type="button"
            className="previous btn btn-light mr-1"
            onClick={previousPage}
          >
            Previous
          </button>
          <button type="submit" className="next btn btn-primary ml-1">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "wizard", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(RefinanceDebtAssumptions);
