import { LOADING_TOGGLE_ACTION, LOGOUT_ACTION } from "../actions/AuthActions";
import * as types from "../actions/types";

const initialState = {
  auth: {
    email: "",
    // idToken: '',
    localId: "",
    expiresIn: "",
    refreshToken: "",
  },
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export default function AuthReducer(state = initialState, action) {
  if (action.type === types.REGISTER_SUCCESS) {
    return {
      ...state,
      auth: action.payload.auth,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === types.LOG_IN_SUCCESS) {
    return {
      ...state,
      auth: action.payload.auth,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      auth: {
        email: "",
        idToken: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
      },
    };
  }

  if (
    action.type === types.REGISTER_FAILED ||
    action.type === types.LOG_IN_FAILED
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
