import * as actions from "../actions/types";
import { takeLatest, put, call, fork, all } from "redux-saga/effects";
import { getToken, expired } from "../../services/AuthService";
import { loginService, signupService } from "../actions/AuthActions";

function verify({ username, code }) {}

function impersonateUser({ username, challenge }) {}

function* callLogOut() {
  const payload = null; // some call
  if (payload) {
    alert("what is this? check the console."); //todo
  }
  yield put({ type: actions.LOG_OUT_SUCCESS, payload });
  window.location.reload();
}

function* callLogIn(action) {
  const payload = yield call(loginService, action.payload);

  if (typeof payload === "string") {
    yield put({ type: actions.LOG_IN_FAILED, payload });
  } else if (payload instanceof Object) {
    yield put({ type: actions.LOG_IN_SUCCESS, payload });
  } else {
    console.log(payload);
    alert("could be a problem, check console");
  }
}

function* callRegister(action) {
  const payload = yield call(signupService, action.payload);

  if (typeof payload === "string") {
    yield put({ type: actions.REGISTER_FAILED, payload });
  } else if (payload instanceof Object) {
    yield put({ type: actions.REGISTER_SUCCESS, payload });
  } else {
    console.log(payload);
    alert("could be a problem, check console");
  }
}

function* callUser(action) {
  const token = getToken();
  if (!token) {
    return false;
  }

  if (expired(token)) {
    yield put({ type: actions.LOG_OUT, token });
    return false;
  }

  yield put({ type: actions.LOG_IN_SUCCESS, token });
  return token;
}

function* callVerify(action) {
  const payload = yield call(verify, action.payload);
  if (
    payload === "SUCCESS" ||
    (typeof payload === "object" &&
      payload.message === "User cannot be confirm. Current status is CONFIRMED")
  ) {
    yield put({ type: actions.VERIFY_SUCCESS, payload });
  } else {
    yield put({ type: actions.VERIFY_FAILED, payload });
  }
}

function* callGetUserData(action, token) {}

function* callGetData(action, token) {
  // action = { ...action }
  yield all([
    // yield put({ type: actions.GET_USER_DATA, action })
  ]);
}

function* callInitApp(action) {
  const token = yield callUser(action);
  if (token) {
    // yield callGetData(action, token);
  }
}

function* getInitSaga() {
  yield takeLatest(actions.INIT_APP, callInitApp);
}

function* logoutSaga() {
  yield takeLatest(actions.LOG_OUT, callLogOut);
}

function* loginSaga() {
  yield takeLatest(actions.LOG_IN, callLogIn);
}

function* registerSaga() {
  yield takeLatest(actions.REGISTER, callRegister);
}

function* getUserSaga() {
  //yield takeLatest(actions.FETCH_LOCAL_COGNITO_USER, callUser)
}

function* verifySaga() {
  yield takeLatest(actions.VERIFY, callVerify);
}

export default function* root() {
  yield all([
    fork(getInitSaga),
    fork(getUserSaga),
    fork(logoutSaga),
    fork(loginSaga),
    fork(registerSaga),
    fork(verifySaga),
  ]);
}
