import {
    CONFIRMED_EDIT_ACCOUNT_ACTION,
    CONFIRMED_GET_ACCOUNT,
} from '../actions/AccountTypes';

const initialState = {
    account: [],
};

export default function AccountReducer(state = initialState, actions) {
    // if (actions.type === CREATE_ACCOUNT_ACTION) {
    //     const post = {
    //         id: Math.random(),
    //         title: 'Post Title 2asdasd',
    //         description: 'Sample Description 2asdasdas',
    //     };

    //     const account = [...state.account];
    //     account.push(post);
    //     return {
    //         ...state,
    //         account,
    //     };
    // }

    if (actions.type === CONFIRMED_EDIT_ACCOUNT_ACTION) {
        const account = [...state.account];
        const postIndex = account.findIndex(
            (post) => post.id === actions.payload.id,
        );

        account[postIndex] = actions.payload;
        return {
            ...state,
            account,
        };
    }

    // if (actions.type === CONFIRMED_CREATE_ACCOUNT_ACTION) {
    //     const account = [...state.account];
    //     account.push(actions.payload);

    //     return {
    //         ...state,
    //         account,
    //     };
    // }

    if (actions.type === CONFIRMED_GET_ACCOUNT) {
        return {
            ...state,
            account: actions.payload,
        };
    }
    return state;
}
