import { getUserById } from "../../services/DatabaseService";

export const USER_GET_CONFIRMED_ACTION = "[get user action] confirmed data";
export const USER_GET_FAILED_ACTION = "[get user action] failed data";

export function getUserAction(token) {
  return async (dispatch) => {
    const id = token.auth.localId;
    if (!id) {
      alert("error in getting user id from token, todo");
    }
    getUserById(id).then((data) => {
      if (data) {
        dispatch(confirmedGetUserAction(data));
      } else {
        dispatch(errorGetUserAction());
      }
    });
  };
}

export function confirmedGetUserAction(data) {
  return {
    type: USER_GET_CONFIRMED_ACTION,
    payload: data,
  };
}

export function errorGetUserAction() {
  return {
    type: USER_GET_FAILED_ACTION,
  };
}
