import {
    INIT_APP,
} from './types';


export const initializeApp = () => {
    return {
        type: INIT_APP,
    }
}
