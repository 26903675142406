import { applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk'
//import rootReducers from './reducers/Index';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import reducers from './reducers';
import rootSaga from './sagas';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory()

const middleware = composeEnhancer(
    applyMiddleware(
        routerMiddleware(history), 
        promiseMiddleware, 
        thunk,
        sagaMiddleware
    )
);

const rootReducer = connectRouter(history)(reducers)

const store = createStore(rootReducer, middleware);

sagaMiddleware.run(rootSaga)

export default store
